.app {
  /*color: #414348;*/
  color: #EFD8B3;
  display:flex;
}

.side {
  flex:auto;
}

.center {
  width:800px;
}

.app-header h1 {
  font-size: 2rem;
  font-weight:700;
  padding:2rem 0;
  margin:0;
  letter-spacing:-0.1rem;
}

.app-logo {
  pointer-events: none;
}

article{
  display:flex;
  font-size:1.5rem;
  letter-spacing:1px;
  font-weight:200;
  padding-bottom:4rem;
}

.article-date{
  flex:1;
  text-align:center;
}

.post{
  flex:5;
}

.post header{
  font-size:2.5rem;
  font-weight:700;
  letter-spacing:-0.1rem;
}

.post footer{
  font-size: 1rem;
  padding:1rem 0;
}

.article-day{
  font-size:3.5rem;
  margin:0;
  padding:0;
}

.article-month-year{
  font-size:0.75rem;
  font-weight:700;
}

.post-image-center{
  display:flex;
  justify-content:center;
}

article img{
  filter: drop-shadow(0 0 0.5rem #000);
}






